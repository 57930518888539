.buttonGroup {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  width: 100%;
  align-items: center;
  flex: 1;
}

.board {
  display: flex;
  flex-direction: column;
  flex: 1;
}
